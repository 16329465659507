import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import logo from "../images/logoheader-3383da94.svg";

const Navbar = () => {
  const handleNavigation = () => {
    window.location.href = "https://www.gob.mx/issste";
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#13322b",
        height: "80px",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: "center",
          height: "100%",
          flexDirection: { xs: "column", sm: "row" }, // Para móviles, el menú se apila
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: "8px", sm: "0" },
          }}
        >
          <img
            src={logo}
            alt="Gobierno de México"
            style={{ height: "50px", marginRight: "16px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: { xs: "16px", sm: "32px" },
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {[
            "Blog",
            "Multimedia",
            "Prensa",
            "Agenda",
            "Acciones y Programas",
            "Documentos",
            "Transparencia",
          ].map((item) => (
            <Box
              key={item}
              onClick={handleNavigation}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#FFFFFF",
                  fontSize: { xs: "12px", sm: "14px" }, // Tamaño de texto en móviles
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
