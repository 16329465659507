import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import escudo from "../images/logoheader-3383da94.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#13322b",
        color: "#FFF",
        padding: "32px 16px",
        textAlign: "center",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Escudo */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <img
              src={escudo}
              alt="Escudo de México"
              style={{ width: "250px", height: "auto" }}
            />
          </Box>
        </Grid>

        {/* Enlaces */}
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              Enlaces
            </Typography>
            {[
              "Participa",
              "Publicaciones Oficiales",
              "Marco Jurídico",
              "Plataforma Nacional de Transparencia",
              "Alerta",
              "Denuncia",
            ].map((item) => (
              <Link
                key={item}
                href="https://www.gob.mx/issste"
                sx={{
                  display: "block",
                  color: "#FFF",
                  textDecoration: "none",
                  fontSize: "14px",
                  marginBottom: "8px",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {item}
              </Link>
            ))}
          </Box>
        </Grid>

        {/* ¿Qué es gob.mx? */}
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              ¿Qué es gob.mx?
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              Es el portal único de trámites, información y participación
              ciudadana.{" "}
              <Link
                href="https://www.gob.mx/issste"
                sx={{
                  color: "#FFF",
                  textDecoration: "underline",
                  "&:hover": { textDecoration: "none" },
                }}
              >
                Leer más
              </Link>
            </Typography>
            {[
              "Portal de datos abiertos",
              "Declaración de accesibilidad",
              "Aviso de privacidad integral",
              "Aviso de privacidad simplificado",
              "Términos y condiciones",
              "Política de seguridad",
              "Mapa de sitio",
            ].map((item) => (
              <Link
                key={item}
                href="https://www.gob.mx/issste"
                sx={{
                  display: "block",
                  color: "#FFF",
                  textDecoration: "none",
                  fontSize: "14px",
                  marginBottom: "8px",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {item}
              </Link>
            ))}
          </Box>
        </Grid>

        {/* Denuncia */}
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              Denuncia contra servidores públicos
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              <Link
                href="https://www.gob.mx/issste"
                sx={{
                  color: "#FFF",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Aquí puedes realizar tu denuncia
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
