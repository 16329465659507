import React from "react";
import Navbar from "./components/Navbar";
import MedicalLicense from "./components/MedicalLicense";
import Footer from "./components/Footer";

const App = () => (
  <>
    <Navbar />
    <MedicalLicense />
    <Footer />
  </>
);

export default App;
