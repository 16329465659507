import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Grid,
} from "@mui/material";

const MedicalLicense = () => {
  const [loading, setLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [showInfo, setShowInfo] = useState(true);

  const handleSearchClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (serialNumber === "0931209624006109") {
        setShowInfo(true);
      } else {
        setShowInfo(false);
        window.location.href = "https://www.gob.mx/issste";
      }
    }, 2000);
  };

  const handleButtonClick = () => {
    window.location.href = "https://www.gob.mx/issste";
  };

  return (
    <Box
      sx={{
        padding: { xs: "16px", sm: "32px" },
        fontFamily: "Arial, sans-serif",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Box sx={{ textAlign: "left", marginBottom: "16px" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "28px",
                color: "#000",
                marginBottom: "4px",
              }}
            >
              RALM
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#555",
              }}
            >
              Registro Automatizado de Licencias Médicas
            </Typography>
          </Box>

          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "16px",
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", textAlign: "left", maxWidth: "280px" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "8px",
                  color: "#000",
                  marginLeft: "-25px",
                }}
              >
                Número de serie*:
              </Typography>
              <TextField
                placeholder="Introduce el número de serie"
                size="small"
                fullWidth
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
                sx={{
                  marginBottom: "16px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "280px",
                flexDirection: { xs: "column", sm: "row" },
                gap: "8px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#ccc",
                  color: "#555",
                  fontWeight: "bold",
                  textTransform: "none",
                  width: "100%",
                  "&:hover": { backgroundColor: "#F0F0F0" },
                }}
                onClick={handleButtonClick}
              >
                Salir
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#A60311",
                  color: "#A60311",
                  fontWeight: "bold",
                  textTransform: "none",
                  width: "100%",
                  "&:hover": { backgroundColor: "#A60311", color: "#fff" },
                }}
                onClick={handleSearchClick}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Buscar"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showInfo && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "400px",
            margin: "0 auto",
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "28px",
              textAlign: "center",
              marginBottom: "16px",
              color: "#000",
            }}
          >
            Licencia Médica
          </Typography>
          <Table
            sx={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <TableBody>
              {[
                ["Número de Serie", "0931209624006109"],
                [
                  "Estatus",
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    Autorizada
                  </span>,
                ],
                ["Fecha de Expedición", "29/11/2024"],
                ["No. de Días otorgados", "8"],
                ["Fecha Inicio", "29/11/2024"],
                ["Fecha Término", "06/12/2024"],
              ].map(([label, value], index) => (
                <TableRow
                  key={label}
                  sx={{
                    height: "28px",
                    borderBottom: index === 5 ? "none" : "1px solid #ddd",
                  }}
                >
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      padding: "2px",
                      textAlign: "left",
                      color: "#000",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {label}:
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      padding: "2px",
                      textAlign: "right",
                      color: index === 1 ? "#007F3E" : "#000",
                    }}
                  >
                    {value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default MedicalLicense;
